<template>
    <div class="subscribe" slot="component">
      <div class="subscribe-title">
        <div class="subscribe-title-subscribe">
          <h2 class="subscribe-title-subscribe-title text-center">{{ $t("start_watching_today") }}</h2>
        </div>
      </div>
      <div class="sub-mb-10"></div>
      <div class="subscribe-plan" dir="ltr">
        <div
        class="loading-container"
        v-if="!isPlans"
        >
        <div v-if="responseError">
          <h1 class="no-plan-text">No Plans Available Now</h1>
        </div>
        <div v-if="!responseError">
          <Loading />
        </div>
        </div>
        <div
          class="subscribe-plan-card"
          id="subscription-plan-card"
          :class="subscribedPlans.length > 0 ? 'py-b' : 'py-tb'"
          v-else
        >
        <div class="subscribe-plan-container mt-20">
          <div v-for="(planObj, index) in availablePlans" :key="index" class="planObj">
            <planCard
              :plan="planObj"
              :gatewayList="gatewayResponse"
              @subscribe="subscribe"
              @cancel="planCancel"
              @close="(response) => closeSubscribeAndOpenOTP(response)"
              :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
            ></planCard>

          </div>
          
        </div>

        </div>
      </div>
    </div>

  <!-- </Popup> -->
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import { store } from "@/store/store";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    subscribePayload: {
      type: Object,
    },
  },
  data() {
    return {
      width: "80%",
      margin: "8% auto",
      radiusType: "full",
      isPlans: false,
      plans: [],
      availablePlans: [],
      filteredAvailablePlans: [],
      subscribeORPurchase: false,
      slider: null,
      placeHolderArr: [1, 2],
      subscribedPlans: [],
      planSelectedForCancellation: null,
      localDisplayLang: null,
      gatewayResponse: [],
      currentPlanSelected: null,
      responseError: "",
      
    };
  },
  computed: {
    ...mapGetters(["availabilityList", "subscriptionList", "subscriberid", "profileid" , "appConfig"]),
  },
  watch: {
    availablePlans(val){
      let planCount = val.length;
      if(planCount === 1){
        this.width = "29%";
      }
      else if(planCount === 2){
        this.width = "55%";
      }
      else{
        this.width = "80%";
      }
    },
    // plans(val) {
    //   if (val.length > 0) {
    //     val.forEach((element) => {
    //       if (element.paymentoptions.includes("OPERATOR")) {
    //         this.checkGatewayForOperator(this.gatewayResponse, element);
    //       }
    //     });
    //   }
    // },

    subscriberid(val) {
      if(val && val === this.profileid && this.currentPlanSelected && this.subscriberid === this.profileid && !this.subscriptionList) {
        this.subscribe(this.currentPlanSelected);
      }
    }
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    if (
      (this.subscribePayload.listType && this.subscribePayload.listType === "onlySubscribe") ||
      this.subscribePayload.listPlans === "ALL"
    ) {
      //list gateways.
      if(this.subscriberid) {
        eventBus.$emit("listGateway");
      } else {
        this.getExternalGateways();
      }
    }


    eventBus.$on("open-rzp-modal", (plan) => {
      this.closePopup();
      if (this.subscriberid) {
        eventBus.$emit("open-home-rzp-modal", plan);
      } else {
        eventBus.$emit("toggle-auth");
      }
    });
  },
  beforeDestroy(){
    eventBus.$off("open-rzp-modal");
  },
  mounted() {
    if (screen.width < 576) {
      this.width = "20%";
    }

    eventBus.$emit("close-detail-popup", false);

    //event for fetching available plans for given availability id.
    eventBus.$on("planlist-subscriptions-response", (response) => {
      if (!response.reason) {
        this.plans = response.data;
        if (this.subscribePayload.listType === "onlySubscribe") {
          this.planSubscriptionList();
        } else if (!this.subscribeORPurchase && this.subscribePayload.listType === "Availabilities") {
          //fetch only available plans matching the props availabilities.
          this.fetchAvailablePlans();
        } 
        
      }
    });

    //event for fetching subscribed plans
    eventBus.$on("subscription-response", (payload) => {
      if (!payload.reason) {
        this.subscribedPlans = payload.data;
        //update subscription list response to store.
        store.commit("setSubscriptionList", this.subscribedPlans);
        this.mergeSubscribedPlans();
      } else {
        this.plans.forEach((plan) => {
          plan.buttonStatus = "Subscribe Now";
        });
        this.showPlanList();
      }
    });

    //event for subscription update
    eventBus.$on("subscriptionUpdate-response", (response) => {
      if (!response.reason) {
        this.subscribePlan();
        this.planSubscriptionList();

        //Subscription Updated event for cancel.
        if (this.planSelectedForCancellation) {
          let data = {
            Status: "cancelled",
            Subscription_Start_Date: this.planSelectedForCancellation.planstartdate,
            Subscription_End_Date: this.planSelectedForCancellation.nextbilling,
            Subscription_Type: this.planSelectedForCancellation.renewtype,
            Plan_Name: this.planSelectedForCancellation.planname,
            // Object_ID: ""
          };

          //clevertap event
          this.subscriptionUpdatedCleverEvent(data);

          //google analytics event
          this.subscriptionCancelled(data);
        }
      }
    });

    eventBus.$on("listgateway-response", (response) => {
      if(response.reason){

      } else {
        this.gatewayResponse = response.data;
        this.subscribePlan();
      }
    });
  },
  methods: {
    ...mapActions(["availabilityDetail", "listExternalGateways"]),
    closeSubscribeAndOpenOTP(response) {
      eventBus.$emit("telcoOtpPopup", response);
      this.closePopup();
    },

    getExternalGateways() {
      this.listExternalGateways().then((response) => {
        if(response.reason){
          this.responseError = response.reason;
        } else {
          this.gatewayResponse = response.data;
          this.subscribePlan();
        }
      });
    },

    //only for subscription.
    subscribePlan() {
      let payload = {
        planvisibility: "YES",
        displaylanguage: this.localDisplayLang,
      };
      eventBus.$emit("planList_subscriptions", payload);
    },

    planSubscriptionList() {
      let payload = {
        subscriptionstatus: "ALL",
      };
      eventBus.$emit("subscriptionList", payload);
    },

    checkGatewayForOperator(operators, plan) {
      if (operators.length > 0) {
        let gateways = operators.filter((el) => {
          return el.gwmode == "OPERATOR";
        });

        let index = gateways.findIndex((element) => {
          return element.ApplicaplePlanList.length > 0 && element.ApplicaplePlanList.includes(plan.planid);
        });

        if (index > -1) {
          switch (gateways[index].gatewayid) {
            case "TELCOSTCBH":
              plan.isSTC = true;
              break;
            case "TELOOREDOOQT":
              plan.isOreedo = true;
              break;
            case "TELOOREDOOOM":
              plan.isOreedo = true;
              break;
            case "TIMWEDUUAE":
              plan.isDUUAE = true;
              break;
            case "TELCOZAINKWT":
              plan.isZainKwt = true;
              break;
            case "TELCOZAINJOR":
              plan.isZainJod = true;
              break;
            case "ETISALETUAE":
              plan.isEtisalatUAE = true;
              break;
            case "TELCOZAINKSA":
              plan.isZainKsa = true;
          }
        }
      }
    },

    showPlanList() {
      this.availablePlans = [];
      this.availablePlans = this.plans;
      
      this.isPlans = true;

      setTimeout(() => {
        let planCard = document.getElementById("subscription-plan-card") || null;
        planCard.scrollLeft = 0;
        if (screen.width >= 1440) {
          planCard.scrollLeft = planCard.clientWidth / 2 - 220;
        } else if (screen.width < 1440) {
          planCard.scrollLeft = planCard.clientWidth / 2 - 150;
        }
      }, 200);
    },

    mergeSubscribedPlans() {
      this.responseError = "";
      this.availablePlans = this.plans.map((plan, index)=>{
          plan['buttonStatus'] = "Subscribe Now";
          return plan;
      });
      this.subscribedPlans.forEach((subscribed) => {
        let index = this.availablePlans.findIndex((element) => {
          return element.planid === subscribed.planid;
        });
        if (index > -1) {
          if (subscribed.subscriptionstatus === "ACTIVE" || subscribed.subscriptionstatus === "ONTRIAL") {
            this.availablePlans[index].buttonStatus = null;
            this.availablePlans[index].subscriptionStatus = subscribed.subscriptionstatus;
            this.availablePlans[index].expiry = subscribed.nextbilling;
          } else if (subscribed.subscriptionstatus === "CANCELLED") {
            if (!this.availablePlans[index].paymentoptions.includes("OPERATOR")) {
              this.availablePlans[index].buttonStatus = "REACTIVATE";
            }
            this.availablePlans[index].subscriptionStatus = subscribed.subscriptionstatus;
            this.availablePlans[index].expiry = subscribed.nextbilling;
          }
          // this.availablePlans.push(this.plans[index]);
        } else {
          subscribed.buttonStatus = null;
          this.availablePlans.unshift(subscribed);
        }

        this.isPlans = true;
      });
    },

    //check for available plans.
    fetchAvailablePlans() {
      this.subscribePayload.availabilities.forEach((availability) => {
        this.plans.forEach((plan) => {
          if (plan.availabilityset.includes(availability.availabilityid)) {
            this.filteredAvailablePlans.push(plan);
          }
        });
      });

      this.availablePlans = this.filteredAvailablePlans;
      

      this.availablePlans.forEach((element) => {
        element.buttonStatus = "Subscribe Now";
      });

      this.isPlans = true;
     
    },

    subscribePlanFromSubscribeOrPurchase() {
      this.subscribeORPurchase = false;
      this.isPlans = false;
      this.listPlans();
    },

    //main button actions
    subscribe(plan) {
      this.currentPlanSelected = plan;
      if (plan.buttonStatus === "Subscribe Now") {
        if (this.subscriberid) {
          this.closePopup();

          let payload = {
            state: true,
            detail: plan,
            gateways: this.gatewayResponse,
            transactionPurpose: "SUBSCRIPTION",
            coupon: plan.coupon ? plan.coupon : null
          };
          eventBus.$emit("enableOverlayLoader", true);
          setTimeout(() => {
            eventBus.$emit("paymentPopup", payload);
            eventBus.$emit("enableOverlayLoader", false);
          },500)
          // eventBus.$emit("noorPayments", payload);
        } else {
          // Open lookup popup.
          let payload = {
            state: true,
            formType: "lookup",
          };

          

          eventBus.$emit("authPopup", payload);

        }
      } else if (plan.buttonStatus === "REACTIVATE") {
        this.isPlans = false;
        let payload = {
          planid: plan.planid,
          params: {
            planstatus: "ACTIVE",
          },
        };
        eventBus.$emit("subscription-update", payload);
      }
    },

    planCancel(plan) {
      this.planSelectedForCancellation = null;
      let index = this.subscriptionList.findIndex((el) => {
        return el.planid === plan.planid;
      });
      if (index > -1) {
        this.planSelectedForCancellation = this.subscriptionList[index];
      }
      let payload = {
        planid: plan.planid,
        params: {
          planstatus: "CANCEL",
        },
      };
      this.isPlans = false;
      eventBus.$emit("subscription-update", payload);
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    planCard: () => import(/* webpackChunkName: "plancard" */ "@/components/Templates/planCard.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),

  },
  mixins: [Utility, googleAnalytics, cleverTap],
  beforeDestroy() {
    eventBus.$off("listgateway-response");
    eventBus.$off("planlist-subscriptions-response");
    eventBus.$off("subscription-response");
    eventBus.$off("subscriptionUpdate-response");
  },
};
</script>

<style lang="scss" scoped>

@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./Subscribe.scss";

.no-plan-text {
  font-size: medium;
  font-weight: 700;
}

</style>
